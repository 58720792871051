import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/CategoryPageLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Analytics`}</h1>
    <p>{`Analytics how to guides and panel reference.`}</p>
    <h2>{`Engagement Cloud`}</h2>
    <hr></hr>
    <p><a parentName="p" {...{
        "href": "/engagement-cloud/"
      }}>{`Engagement Cloud`}</a>{` is part of CoreMedia Experience Platform. It contains features like lead activation, contact management, segmentation, user creation, analytics, among others.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      